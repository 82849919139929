<template>
    <div>
        <div id="editor" style="width:1024px;height:500px;"></div>
    </div>
</template>
<script >
const UE = window.UE;
export default{
    data() {
        return {

        }
    },
    mounted() {
        var ue = UE.getEditor('editor');
        setTimeout(()=> {
           ue.focus()
           ue.execCommand('insertHtml', '<div>21312312</div>')
        },1000)
    //    this.$nextTick(function(){
    //        ue.focus()
    //     //    ue.execCommand('insertHtml', data.explain)
    //    })
    }
}
</script>